// dependencies
import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
// helpers
import { getTypeKey } from '@helpers/strapi'
// context
import { useStrapiContext } from '@context/strapiContext'
// components
import PageRecentlyViewed from '@shared/recently-viewed'
import PageMarkdown from './content-types/Markdown'
import PageBanner from './content-types/Banner'
import PageSearchQuery from './content-types/SearchQuery'
import PageButtonList from './content-types/ButtonList'
import PageGrid from './content-types/Grid'
import PageSlider from './content-types/Slider'
import PageAccordion from './content-types/Accordion'
import PageAccordionList from './content-types/AccordionList'
import PageFinanceBannerGroup from './content-types/FinanceBannerGroup'
import PageHtml from './content-types/HTML'
import ContentGroup from './content-types/ContentGroup'
import PageDeliveryWidget from './content-types/DeliveryWidget'

const avaiableTypes = {
  // ? Breadcrumbs are rendered at the top of the Page by default, there is no need to render them here.
  PageBreadCrumbs: null,
  PageBanner,
  PageMarkdown,
  PageSearchQuery,
  PageButtonList,
  PageGrid,
  PageSlider,
  PageAccordion,
  PageAccordionList,
  PageRecentlyViewed,
  PageFinanceBannerGroup,
  PageHtml,
  PageDeliveryWidget,
}

const toComponent = (contentData, index) => {
  if (!contentData?.__typename) {
    return null
  }
  if (contentData.__typename === 'PageContentGroup') {
    return <ContentGroup data={contentData?.ContentGroup} key={getTypeKey(contentData)} index={index} />
  }
  const Component = avaiableTypes[contentData.__typename]
  return Component ? <Component contentIndex={index} data={contentData} key={getTypeKey(contentData)} /> : null
}

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    display: 'block',
    maxWidth: '104rem',
    '& .MuiGrid-spacing-xs-2:not(.grid-wrapper), & .MuiGrid-spacing-xs-4:not(.grid-wrapper), & .MuiGrid-spacing-xs-6:not(.grid-wrapper)': {
      margin: 0,
    },
    '& > div:not(.page-content-group)': {
      '&:not(.grid-wrapper)': {
        width: '100%',
      },
    },
    '& > div.page-content-group > div': {
      '&:not(.grid-wrapper)': {
        width: '100%',
      },
    },
  },
}))

const ContentGenerator = () => {
  const classes = useStyles()
  const {
    page: { ContentTypes, SEO },
  } = useStrapiContext()

  if (ContentTypes && Array.isArray(ContentTypes)) {
    return (
      <Grid container className={classes.container}>
        {ContentTypes.map(toComponent)}
        {SEO?.Accordion?.Accordion && <PageAccordion data={SEO?.Accordion?.Accordion} gridWrapper />}
      </Grid>
    )
  }
  return null
}

export default ContentGenerator
