// dependencies
import React, { useEffect, useRef, useCallback } from 'react'
import { graphql } from 'gatsby'
import { makeStyles, Typography, Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useInView } from 'react-intersection-observer'
// helpers
import { slugify } from '@helpers/string-helper'
import { mergeRefs, throttle } from '@helpers/events'
// context
import { useCommonQuestionsCxt } from '@context/commonQuestions'
// components
import Accordion from './Accordion'

const useStyles = makeStyles(theme => ({
  listWrapper: {
    width: '100%',
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
    },
  },
  textWrapper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  listHeading: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.h5.fontSize,
  },
}))

const AccordionList = ({ data }) => {
  const { setActiveNav, pushSectionRef } = useCommonQuestionsCxt()
  const {
    AccordionList: { Accordions, Title, id },
  } = data
  const sectionRef = useRef()
  const skip = typeof setActiveNav !== 'function'
  const [inViewRef, inView] = useInView({ threshold: 0.8, skip })
  const classes = useStyles()
  const sectionId = `${slugify(Title)}-section`

  return (
    <Grid item md={12} component="section" data-testid={id} id={sectionId} ref={mergeRefs(inViewRef, sectionRef)}>
      <div className={classes.textWrapper}>
        <Typography component="h3" className={classes.listHeading}>
          {Title}
        </Typography>
      </div>
      <div className={classes.listWrapper}>
        {Accordions &&
          Accordions.map((accordion_data, index) => (
            <Accordion data={accordion_data.Accordion} index={index} key={data} gridWrapper={false} />
          ))}
      </div>
    </Grid>
  )
}

AccordionList.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AccordionList

export const accordionListFragment = graphql`
  fragment StrapiPageAccordionListFragment on StrapiAccordionList {
    id
    Title
    Accordions {
      Accordion {
        id
        Heading
        Markdown {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`
