import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { objectOf, node, any } from 'prop-types'
import { getRegion } from '@helpers/geo-location'
import NoSsr from '@material-ui/core/NoSsr'
import Banner from './Banner/index'
import '../../../assets/css/components/shared/button.sass'

const FinanceBannerGroup = ({ data }) => {
  const { FinanceBannerGroup: FinanceBannerGroupData } = data
  const region = getRegion()
  return (
    <NoSsr>
      <Banner data={FinanceBannerGroupData[region].Banner} />
    </NoSsr>
  )
}

FinanceBannerGroup.propTypes = {
  data: objectOf(any),
}

export const StrapiFinanceBannerGroupFragment = graphql`
  fragment StrapiFinanceBannerGroupFragment on StrapiFinanceBannerGroup {
    __typename
    FL {
      Banner {
        ...StrapiPageBannerFragment
      }
    }
    SE {
      Banner {
        ...StrapiPageBannerFragment
      }
    }
    TX {
      Banner {
        ...StrapiPageBannerFragment
      }
    }
    PR {
      Banner {
        ...StrapiPageBannerFragment
      }
    }
  }
`

export default FinanceBannerGroup
