// dependencies
import React, { useMemo, useEffect } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { useMediaQuery, responsiveFontSizes, createTheme, ThemeProvider, Grid } from '@material-ui/core'
import { theme as rtgTheme } from '@rtgdev/design-system'
// helpers
import { selectRegionBasedContent, hasSearchQuery } from '@helpers/strapi'
import { getRegion } from '@helpers/geo-location'
import { setupAnalytics } from '@helpers/google-tag-manager'
// hooks
import useDisabledCoupons from '@hooks/useDisabledCoupons'
// context
import { StrapiContextProvider } from '@context/strapiContext'
// components
import Layout from '@components/layout'
import ContentGenerator from './ContentGenerator'
import PageSEO from './content-types/SEO'
import PageBreadcrumbs from './content-types/Breadcrumbs'
import Banner from './content-types/Banner'

const pageTheme = responsiveFontSizes(
  createTheme({
    ...rtgTheme,
    breakpoints: {
      values: {
        xs: 0,
        sm: 639,
        md: 1024,
        lg: 1480,
        xl: 1920,
      },
    },
  }),
)

const StrapiCMSPage = ({ data, navigate, params, pageContext }) => {
  const isMobile = useMediaQuery(pageTheme.breakpoints.down('sm'))
  const region = getRegion()
  const disabledCoupons = useDisabledCoupons()

  const matchPath = pageContext?.matchPath
  const page = data?.allStrapiPage?.edges?.[0]?.node || {}
  const SEO = page?.SEO?.SEO
  const { id, PageType, Title, Route } = page
  const defaultFinanceBanner = data?.allStrapiFinanceBannerGroup?.edges?.[0]?.node[region] || {}

  const ContentTypes = useMemo(() => selectRegionBasedContent(region, page), [region, page])

  useEffect(() => {
    const foundPixleeWidget = ContentTypes?.find(
      contentType =>
        contentType.__typename === 'PageHtml' && contentType.HTML?.HTML?.toLowerCase?.().includes('pixlee'),
    )
    if (foundPixleeWidget) window?.Pixlee?.init?.({ apiKey: 'hNRL7YymtOeY7jfO2XFe' })
  }, [ContentTypes])

  const isPLP = useMemo(() => (PageType === 'plp' || PageType === 'sub_category') && hasSearchQuery(ContentTypes), [
    PageType,
    ContentTypes,
  ])

  const isFinanceBannerOnPage = ContentTypes?.some(
    ({ __typename, Banner: InPageBanner }) =>
      __typename === 'PageBanner' && InPageBanner?.id === defaultFinanceBanner?.Banner?.id,
  )

  const { BreadCrumbs } = useMemo(
    () => ContentTypes?.find(({ __typename, BreadCrumbs: BC }) => __typename === 'PageBreadCrumbs' && BC) || {},
    [ContentTypes],
  )

  const contextValue = {
    navigate,
    params,
    isMobile,
    disabledCoupons,
    page: { id, PageType, Title, Route, SEO, ContentTypes, BreadCrumbs, isPLP, matchPath },
  }

  useEffect(() => {
    setupAnalytics({
      pageData: {
        type: page.PageType,
        title: page.Title,
        cid: page.id,
        path: page.Route,
      },
    })
  }, [page])

  const { Crumbs } = BreadCrumbs || {}

  return (
    <ThemeProvider theme={pageTheme}>
      <StrapiContextProvider value={contextValue}>
        <Layout>
          <PageSEO data={SEO} breadcrumbs={Crumbs} />
          <main id="content" aria-label="Content" tabIndex="-1" className="generated-page" data-testid={id}>
            <PageBreadcrumbs />
            <ContentGenerator />
            {isPLP && !isFinanceBannerOnPage && (
              <Grid container>
                <Banner data={defaultFinanceBanner} />
              </Grid>
            )}
          </main>
        </Layout>
      </StrapiContextProvider>
    </ThemeProvider>
  )
}

export const StrapiPageFragment = graphql`
  fragment StrapiPageFragment on StrapiPage {
    id
    PageType
    Title
    Route
    SEO {
      ...StrapiPageSeoFragment
    }
    DefaultContent {
      __typename
      ... on PageBanner {
        __typename
        Banner {
          ...StrapiPageBannerFragment
        }
      }
      ... on PageBreadCrumbs {
        __typename
        BreadCrumbs {
          ...StrapiPageBreadCrumbsFragment
        }
      }
      ... on PageMarkdown {
        Markdown {
          ...StrapiPageMarkdownFragment
        }
      }
      ... on PageSearchQuery {
        SearchQuery {
          ...SearchQueryStrapiFragment
        }
      }
      ... on PageButtonList {
        ButtonList {
          ...StrapiButtonListFragent
        }
      }
      ... on PageGrid {
        __typename
        Grid {
          ...StrapiPageGridFragment
        }
      }
      ... on PageSlider {
        Slider {
          ...StrapiSliderfragment
        }
      }
      ... on PageContentGroup {
        ContentGroup {
          ...StrapiContentGroupFragment
        }
      }
      ... on PageAccordion {
        Accordion {
          ...StrapiPageAccordionFragment
        }
      }
      ... on PageAccordionList {
        AccordionList {
          ...StrapiPageAccordionListFragment
        }
      }
      ... on PageFinanceBannerGroup {
        FinanceBannerGroup {
          ...StrapiFinanceBannerGroupFragment
        }
      }
      ... on PageRecentlyViewed {
        ShowRecentlyViewed
      }
      ... on PageHtml {
        HTML {
          ...StrapiHtmlFragment
        }
      }
      ... on PageDeliveryWidget {
        ...DeliveryWidgetFragment
      }
    }
    Content_SE {
      __typename
      ... on PageBanner {
        __typename
        Banner {
          ...StrapiPageBannerFragment
        }
      }
      ... on PageBreadCrumbs {
        __typename
        BreadCrumbs {
          ...StrapiPageBreadCrumbsFragment
        }
      }
      ... on PageMarkdown {
        Markdown {
          ...StrapiPageMarkdownFragment
        }
      }
      ... on PageSearchQuery {
        SearchQuery {
          ...SearchQueryStrapiFragment
        }
      }
      ... on PageButtonList {
        ButtonList {
          ...StrapiButtonListFragent
        }
      }
      ... on PageGrid {
        __typename
        Grid {
          ...StrapiPageGridFragment
        }
      }
      ... on PageSlider {
        Slider {
          ...StrapiSliderfragment
        }
      }
      ... on PageContentGroup {
        ContentGroup {
          ...StrapiContentGroupFragment
        }
      }
      ... on PageAccordion {
        Accordion {
          ...StrapiPageAccordionFragment
        }
      }
      ... on PageAccordionList {
        AccordionList {
          ...StrapiPageAccordionListFragment
        }
      }
      ... on PageFinanceBannerGroup {
        FinanceBannerGroup {
          ...StrapiFinanceBannerGroupFragment
        }
      }
      ... on PageRecentlyViewed {
        ShowRecentlyViewed
      }
      ... on PageHtml {
        HTML {
          ...StrapiHtmlFragment
        }
      }
      ... on PageDeliveryWidget {
        ...DeliveryWidgetFragment
      }
    }
    Content_FL {
      __typename
      ... on PageBanner {
        __typename
        Banner {
          ...StrapiPageBannerFragment
        }
      }
      ... on PageBreadCrumbs {
        __typename
        BreadCrumbs {
          ...StrapiPageBreadCrumbsFragment
        }
      }
      ... on PageMarkdown {
        Markdown {
          ...StrapiPageMarkdownFragment
        }
      }
      ... on PageSearchQuery {
        SearchQuery {
          ...SearchQueryStrapiFragment
        }
      }
      ... on PageButtonList {
        ButtonList {
          ...StrapiButtonListFragent
        }
      }
      ... on PageGrid {
        __typename
        Grid {
          ...StrapiPageGridFragment
        }
      }
      ... on PageSlider {
        Slider {
          ...StrapiSliderfragment
        }
      }
      ... on PageContentGroup {
        ContentGroup {
          ...StrapiContentGroupFragment
        }
      }
      ... on PageAccordion {
        Accordion {
          ...StrapiPageAccordionFragment
        }
      }
      ... on PageAccordionList {
        AccordionList {
          ...StrapiPageAccordionListFragment
        }
      }
      ... on PageFinanceBannerGroup {
        FinanceBannerGroup {
          ...StrapiFinanceBannerGroupFragment
        }
      }
      ... on PageRecentlyViewed {
        ShowRecentlyViewed
      }
      ... on PageHtml {
        HTML {
          ...StrapiHtmlFragment
        }
      }
      ... on PageDeliveryWidget {
        ...DeliveryWidgetFragment
      }
    }
    Content_TX {
      __typename
      ... on PageBanner {
        __typename
        Banner {
          ...StrapiPageBannerFragment
        }
      }
      ... on PageBreadCrumbs {
        __typename
        BreadCrumbs {
          ...StrapiPageBreadCrumbsFragment
        }
      }
      ... on PageMarkdown {
        Markdown {
          ...StrapiPageMarkdownFragment
        }
      }
      ... on PageSearchQuery {
        SearchQuery {
          ...SearchQueryStrapiFragment
        }
      }
      ... on PageButtonList {
        ButtonList {
          ...StrapiButtonListFragent
        }
      }
      ... on PageGrid {
        __typename
        Grid {
          ...StrapiPageGridFragment
        }
      }
      ... on PageSlider {
        Slider {
          ...StrapiSliderfragment
        }
      }
      ... on PageContentGroup {
        ContentGroup {
          ...StrapiContentGroupFragment
        }
      }
      ... on PageAccordion {
        Accordion {
          ...StrapiPageAccordionFragment
        }
      }
      ... on PageAccordionList {
        AccordionList {
          ...StrapiPageAccordionListFragment
        }
      }
      ... on PageFinanceBannerGroup {
        FinanceBannerGroup {
          ...StrapiFinanceBannerGroupFragment
        }
      }
      ... on PageRecentlyViewed {
        ShowRecentlyViewed
      }
      ... on PageHtml {
        HTML {
          ...StrapiHtmlFragment
        }
      }
      ... on PageDeliveryWidget {
        ...DeliveryWidgetFragment
      }
    }
    Content_OOM {
      __typename
      ... on PageBanner {
        __typename
        Banner {
          ...StrapiPageBannerFragment
        }
      }
      ... on PageBreadCrumbs {
        __typename
        BreadCrumbs {
          ...StrapiPageBreadCrumbsFragment
        }
      }
      ... on PageMarkdown {
        Markdown {
          ...StrapiPageMarkdownFragment
        }
      }
      ... on PageSearchQuery {
        SearchQuery {
          ...SearchQueryStrapiFragment
        }
      }
      ... on PageButtonList {
        ButtonList {
          ...StrapiButtonListFragent
        }
      }
      ... on PageGrid {
        __typename
        Grid {
          ...StrapiPageGridFragment
        }
      }
      ... on PageSlider {
        Slider {
          ...StrapiSliderfragment
        }
      }
      ... on PageContentGroup {
        ContentGroup {
          ...StrapiContentGroupFragment
        }
      }
      ... on PageAccordion {
        Accordion {
          ...StrapiPageAccordionFragment
        }
      }
      ... on PageAccordionList {
        AccordionList {
          ...StrapiPageAccordionListFragment
        }
      }
      ... on PageFinanceBannerGroup {
        FinanceBannerGroup {
          ...StrapiFinanceBannerGroupFragment
        }
      }
      ... on PageRecentlyViewed {
        ShowRecentlyViewed
      }
      ... on PageHtml {
        HTML {
          ...StrapiHtmlFragment
        }
      }
      ... on PageDeliveryWidget {
        ...DeliveryWidgetFragment
      }
    }
  }
`

export const StrapiPageQuery = graphql`
  query strapiPageQuery($pageId: String!) {
    allStrapiPage(filter: { id: { eq: $pageId } }) {
      edges {
        node {
          ...StrapiPageFragment
        }
      }
    }
    allStrapiFinanceBannerGroup(filter: { Title: { eq: "Production Finance Banner Group" } }) {
      edges {
        node {
          id
          Title
          SE {
            Banner {
              ...StrapiPageBannerFragment
            }
          }
          FL {
            Banner {
              ...StrapiPageBannerFragment
            }
          }
          TX {
            Banner {
              ...StrapiPageBannerFragment
            }
          }
          PR {
            Banner {
              ...StrapiPageBannerFragment
            }
          }
        }
      }
    }
  }
`

StrapiCMSPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
}

export default StrapiCMSPage
