import React from 'react'
import { string, object } from 'prop-types'
import SimpleSlider from '@templates/strapi-cms/content-types/Slider'
import ProductTile from '../product/product-tile'

export default class RecentlyViewed extends React.Component {
  state = {
    recentlyViewedArray: [],
  }

  componentDidMount() {
    let { sku } = this.props
    if (!sku) {
      sku = '000000'
    }
    this.addToRecentlyViewed(sku)
  }

  addToRecentlyViewed = recentlyViewedSKU => {
    if (recentlyViewedSKU) {
      const recentlyViewedString = localStorage.getItem('recentlyViewedV2')
      const recentlyViewedArray = recentlyViewedString ? recentlyViewedString.split(',') : []
      if (recentlyViewedArray) {
        if (!recentlyViewedArray.includes(recentlyViewedSKU)) {
          recentlyViewedArray.unshift(recentlyViewedSKU)
        }
        if (recentlyViewedArray.length > 9) {
          recentlyViewedArray.pop()
        }
        localStorage.setItem('recentlyViewedV2', recentlyViewedArray)
      } else if (recentlyViewedSKU) {
        localStorage.setItem('recentlyViewedV2', [recentlyViewedSKU])
      }
      this.setState({
        recentlyViewedArray: recentlyViewedArray
          ? recentlyViewedArray.filter(sku => sku !== recentlyViewedSKU && sku !== '000000')
          : [],
      })
    }
  }

  render() {
    const { recentlyViewedArray } = this.state
    const { data } = this.props

    if (!data.ShowRecentlyViewed) return null

    return (
      <>
        {recentlyViewedArray && recentlyViewedArray.length > 0 && (
          <div className="grid-container">
            <div className="product-collection-slider grid-x grid-margin-x grid-margin-y grid-padding-y">
              <div className="cell small-12">
                <SimpleSlider
                  data={{
                    id: 'slider:recently-viewed',
                    Heading: 'Recently Viewed',
                  }}
                  noExtraMargin
                >
                  {recentlyViewedArray.map((sku, index) => (
                    <ProductTile
                      id={`product-title:${sku}`}
                      sku={sku}
                      key={sku}
                      viewType="grid"
                      antiFlickering
                      index={index}
                      sliderTitle="Recently Viewed"
                    />
                  ))}
                </SimpleSlider>
              </div>
            </div>
          </div>
        )}
      </>
    )
  }
}

RecentlyViewed.propTypes = {
  sku: string,
  data: object.isRequired,
}
