import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Grid, Button } from '@material-ui/core'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import { graphql } from 'gatsby'
import Axios from 'axios'
import classNames from 'classnames'
import { getZipFromSearch } from '@helpers/geo-location'
import { fonts, breakPoints } from '@constants/styles'
import DeliveryInput from './DeliveryInput'

const useStyles = makeStyles(theme => ({
  gridContainer: {
    [`@media screen and (max-width: ${breakPoints.small})`]: {
      paddingLeft: '10px',
    },
  },
  textContainer: {
    textAlign: 'center',
    '& h2': {
      color: 'white',
      fontWeight: 'bold',
      padding: '5rem 0 1rem 0',
      letterSpacing: '.3rem',
      [`@media screen and (max-width: ${breakPoints.small})`]: {
        letterSpacing: '0',
        padding: '25px 5px',
      },
    },
    '& p': {
      color: 'white',
      fontSize: `${fonts.txtMedium}`,
      padding: '0 0 4rem 0',
      letterSpacing: '.1rem',
      [`@media screen and (max-width: ${breakPoints.small})`]: {
        letterSpacing: '0.05rem',
        padding: '0 5px 25px',
      },
    },
  },
  deliveryMessage: {
    minHeight: '5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& p': {
      color: 'white',
      fontSize: `${fonts.txtMedium}`,
      letterSpacing: '.1rem',
      [`@media screen and (max-width: ${breakPoints.small})`]: {
        padding: '5px 10px',
        marginTop: '20px',
        width: 'calc(100vw - 30px)',
        marginBottom: '0',
      },
    },
    [`@media screen and (max-width: ${breakPoints.medium})`]: {
      flexDirection: 'column',
      textAlign: 'center',
    },
  },
  button: {
    width: 'auto',
    backgroundColor: '#c4e5ff',
    color: '#07263b',
    borderRadius: '0',
    fontWeight: 'bold',
    padding: '0 20px 0 20px',
    marginLeft: '-10px',
    '&:hover': {
      color: '#c4e5ff',
    },
    [`@media screen and (max-width: ${breakPoints.small})`]: {
      padding: '0 10px',
      marginLeft: '0',
    },
  },
  formContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  form: {
    width: '65%',
  },
  span: {
    display: 'flex',
    alignItems: 'center',
  },
  errorIcon: {
    margin: '0 10px 0 10px',
  },
}))

const DeliveryWidget = ({ data: strapiData, className = '', styles = {}, trackingData = null }) => {
  const data = strapiData.DeliveryWidget
  const [addressInput, setAddressInput] = useState('')
  const [showMessage, setShowMessage] = React.useState(false)
  const [deliveryMessage, setDeliveryMessage] = React.useState('')

  const classes = useStyles()

  const onChange = event => {
    setAddressInput(event.target.value)
  }

  useEffect(() => {
    setShowMessage(false)
  }, [])
  useEffect(() => {}, [deliveryMessage])

  const handleSubmit = async event => {
    event.preventDefault()
    const isPRZip =
      (Number(addressInput) >= 600 && Number(addressInput) <= 799) ||
      (Number(addressInput) >= 900 && Number(addressInput) <= 999)

    /* explicitly define the allowed URLs */
    const allowedUSDevHosts = ['rtg-dev.com', 'www.rtg-dev.com']
    const allowedUSProdHosts = ['roomstogo.com', 'www.roomstogo.com']

    /* grab the domain from the URL */
    let windowHost = ''
    if (typeof window !== 'undefined') {
      windowHost = window.location.hostname
    }

    /* if the user is on .com dev or prod and enters a Puerto Rico zipcode, redirect to the PR website */
    if (allowedUSDevHosts.includes(windowHost) && isPRZip) {
      window.location.href = `https://www.rtg-dev.pr?zip=${Number(addressInput)}`
    } else if (allowedUSProdHosts.includes(windowHost) && isPRZip) {
      window.location.href = `https://www.roomstogo.pr?zip=${Number(addressInput)}`
    } else {
      const zip = await getZipFromSearch(addressInput)
      if (Object.is(zip, null)) {
        // Please enter valid US Address
        setDeliveryMessage(
          <span className={classNames(classes.span)}>
            <ErrorOutlineOutlinedIcon className={classNames(classes.errorIcon)} />
            Please enter a valid US City, State, or Zip Code.
          </span>,
        )
        setShowMessage(true)
      } else {
        const canDeliver = await Axios.get(`https://location.rtg-dev.com/?zip=${zip}`)
        const shippingZones = ['A', 'B', 'C', 'D', 'E', 'F', 'G']
        if (canDeliver) {
          if (!canDeliver.data.response.browse_only && shippingZones.includes(canDeliver.data.response.delivery_zone)) {
            setDeliveryMessage(data?.SuccessText ? data.SuccessText : 'Good news! You are in our delivery area!')
            setShowMessage(true)
          } else {
            setDeliveryMessage(
              data?.FailureText
                ? data.FailureText
                : 'Sorry, you are outside our delivery area. Some décor items, however, are available via UPS.',
            )
            setShowMessage(true)
          }
        }
      }
    }
  }
  const containerStyle = {
    contentContainer: {
      minHeight: 'max-content',
      backgroundImage: `url(${data?.BackgroundImage?.url})` || 'transparent',
      backgroundColor: '#082233',
    },
    msgBackgroundColor: {
      backgroundColor: data?.BackgroundColor.ColorHex || '#395162',
      padding: '5px 10px',
      borderRadius: '5px',
      [`@media screen and (max-width: ${breakPoints.small})`]: {
        borderRadius: '0',
      },
    },
  }

  return (
    <div style={containerStyle.contentContainer}>
      <Grid container>
        <Grid item md={12}>
          <div className={classNames(classes.textContainer)}>
            <h2>{data?.Title || 'CHECK TO SEE IF WE DELIVER TO YOUR LOCATION'}</h2>
            <p>{data?.SubLabel || 'Enter your City, State, or Zip Code to find out if we deliver to you.'}</p>
          </div>
        </Grid>
        <Grid item md={3} />
        <Grid item md={6} className={classNames(classes.gridContainer)}>
          <div className={classNames(classes.formContainer)}>
            <form onSubmit={e => handleSubmit(e)} className={classNames(classes.form)}>
              <DeliveryInput
                placeholderText={data?.InputPlaceholder || 'City, State, or Zip Code'}
                addressInput={addressInput}
                onChange={onChange}
                noAutoComplete
                id="Header"
              />
            </form>
            <Button
              className={classNames(classes.button)}
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              type="submit"
              onClick={e => handleSubmit(e)}
            >
              {data?.ButtonText || 'Check Now'}
            </Button>
          </div>
        </Grid>

        <Grid item md={3} />
        <Grid item md={12}>
          <div className={classNames(classes.deliveryMessage)}>
            {showMessage && <p style={containerStyle.msgBackgroundColor}>{deliveryMessage}</p>}
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

DeliveryWidget.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
  styles: PropTypes.object,
  trackingData: PropTypes.object,
}

export const StrapiButtonFragment = graphql`
  fragment DeliveryWidgetFragment on PageDeliveryWidget {
    id
    DeliveryWidget {
      Title
      SubLabel
      ButtonText
      InputPlaceholder
      SuccessText
      FailureText
      BackgroundColor {
        ColorHex
      }
      BackgroundImage {
        ...StrapiImageFragment
      }
    }
  }
`
export default DeliveryWidget
