// dependencies
import React, { useMemo } from 'react'
import { graphql } from 'gatsby'
import { Grid, makeStyles } from '@material-ui/core'
import styled from 'styled-components'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import PropTypes from 'prop-types'
import sortBy from 'lodash/sortBy'
// context
import { useStrapiContext } from '@context/strapiContext'
// components
import StrapiLink from './Link'
import '@comp-sass/shared/breadcrumbs.sass'

const useStyles = makeStyles(theme => ({
  container: {
    width: 'unset',
    margin: 0,
    padding: `${theme.spacing(1)}px 0`,
    maxWidth: '104rem',
  },
  breadcrumbsBox: {
    lineHeight: '20px',
    '& a': {
      textDecoration: 'underline',
      color: theme.palette.primary.main,
    },
  },
  breadcrumbsCenter: {
    justifyContent: 'center',
    marginTop: '0.5rem',
  },
  divider: {
    margin: '0 0.6rem',
    color: theme.palette.text.primary,
    userSelect: 'none',
  },
  list: {
    listStyle: 'none',
    display: 'block',
  },
  listItem: {
    display: 'inline-block',
    fontSize: theme.typography.body2.fontSize,
    verticalAlign: 'middle',
    height: '1.2rem',
  },
  breadcrumbLink: {
    fontSize: theme.typography.body2.fontSize,
    display: 'inline-block',
  },
  lastBreadcrumbLink: {
    fontSize: theme.typography.body2.fontSize,
    display: 'inline-block',
    color: theme.palette.text.primary,
    padding: 0,
  },
}))

const breadcrumbsTypes = {
  LINK: 'LINK',
  DIVIDER: 'DIVIDER',
  CURRENT: 'CURRENT',
}

const topStrapiLink = ({ url, label, category }, index) => ({
  Link: {
    id: `product-bc-${index}`,
    DisplayText: label,
    InternalUrl: url,
    Title: category,
  },
})

/**
 * Creates Breadcrumbs array while adding homepage link and dividers
 * @param {object[]} crumbs
 * @returns {Object}
 */
const createBreadCrumbsList = crumbs =>
  crumbs.reduce(
    (links, { Link: LinkData }, index) => {
      if (!LinkData) return links
      const divider = { type: breadcrumbsTypes.DIVIDER, id: `${LinkData.id}-divider` }
      if (index === crumbs.length - 1) {
        return [...links, divider, { type: breadcrumbsTypes.CURRENT, ...LinkData }]
      }
      if (LinkData) {
        return [...links, divider, { type: breadcrumbsTypes.LINK, ...LinkData }]
      }
      return links
    },
    [{ InternalUrl: '/', DisplayText: 'Home', id: 'home-breadcrumbs', type: breadcrumbsTypes.LINK }],
  )

const StrapiBreadcrumbs = ({
  storeBreadcrumbs = null,
  productBreadcrumbs = null,
  productTitle = null,
  isPDP = false,
}) => {
  const {
    page: { BreadCrumbs, SEO },
  } = useStrapiContext()
  const classes = useStyles()

  const links = useMemo(() => {
    if (Array.isArray(productBreadcrumbs) && productBreadcrumbs.length > 0) {
      return createBreadCrumbsList(sortBy(productBreadcrumbs, ['level']).map(topStrapiLink))
    }
    if (Array.isArray(storeBreadcrumbs) && storeBreadcrumbs.length > 0) {
      return createBreadCrumbsList(storeBreadcrumbs.map(topStrapiLink))
    }
    return createBreadCrumbsList(BreadCrumbs?.Crumbs || [])
  }, [BreadCrumbs, productBreadcrumbs, storeBreadcrumbs])

  if (!BreadCrumbs?.Crumbs && !productBreadcrumbs && !storeBreadcrumbs) {
    if (!SEO?.PageHeading && !productTitle) {
      return null
    }
    return (
      <Grid container className={`${classes.container} ${classes.breadcrumbsCenter}`}>
        <Grid item md={12} component="nav" className={classes.breadcrumbsBox} aria-label="breadcrumb">
          <h1 className={classes.lastBreadcrumbLink}>{productTitle || SEO?.PageHeading}</h1>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container className={classes.container}>
      <Grid
        item
        md={12}
        component="nav"
        className={`${classes.breadcrumbsBox} ${isPDP ? 'breadcrumb-pdp' : ''}`}
        aria-label="breadcrumb"
      >
        <ol className={classes.list}>
          {links.map(({ type, ...data }) => {
            const view = {
              [breadcrumbsTypes.LINK]: (
                <li className={classes.listItem} key={data.id}>
                  <StrapiLink className={classes.breadcrumbLink} data={data} />
                </li>
              ),
              [breadcrumbsTypes.DIVIDER]: (
                <li className={classes.listItem} key={data.id}>
                  <NavigateNextIcon fontSize="small" className={classes.divider} />
                </li>
              ),
              [breadcrumbsTypes.CURRENT]: (
                <li className={classes.listItem} key={data.id}>
                  <h1 className={classes.lastBreadcrumbLink}>{productTitle || data?.DisplayText || data?.Title}</h1>
                </li>
              ),
            }
            return view[type] || null
          })}
        </ol>
      </Grid>
    </Grid>
  )
}

StrapiBreadcrumbs.propTypes = {
  productBreadcrumbs: PropTypes.array,
  storeBreadcrumbs: PropTypes.array,
  productTitle: PropTypes.string,
  isPDP: PropTypes.bool,
}

export const StrapiPageBreadCrumbsFragment = graphql`
  fragment StrapiPageBreadCrumbsFragment on StrapiBreadCrumbs {
    id
    Crumbs {
      Link {
        ...StrapiLinkFragment
      }
    }
  }
`

export default StrapiBreadcrumbs
