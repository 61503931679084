// dependencies
import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { removeFirstAndLastSlash } from '@helpers/string-helper'
import { isLastIndex } from '@helpers/array-helper'

const setSchemaType = schema =>
  schema && typeof schema === 'object'
    ? {
        '@type': schema._type,
        ...schema,
      }
    : null

const formatLD_Schema = (LD_Schema = {}) => {
  const contactPoint = setSchemaType(LD_Schema?.contactPoint)
  const potentialAction = setSchemaType(LD_Schema?.potentialAction)
  const geo = setSchemaType(LD_Schema?.geo)
  const address = setSchemaType(LD_Schema?.address)

  const { _context = null, _type = null, schema } = LD_Schema
  return JSON.stringify({
    ...schema,
    '@context': _context,
    '@type': _type,
    contactPoint,
    potentialAction,
    geo,
    address,
  })
}

const formatStructuredBreadcrumbsLinks = crumbs => {
  const baseUrl = removeFirstAndLastSlash(process.env.APP_BASE_URL || 'https://roomstogo.pr')
  return JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: crumbs.map(({ Link }, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      name: Link?.DisplayText,
      item: !isLastIndex(index, crumbs.length)
        ? `${baseUrl}/${removeFirstAndLastSlash(Link?.InternalUrl || '')}`
        : undefined,
    })),
  })
}

const StrapiSeo = ({ data, breadcrumbs }) => {
  if (!data) {
    return null
  }
  const { MetaDescription, Canonical, LD_Schema, MetaRobots, PageTitle } = data
  return (
    <>
      {PageTitle && <Helmet title={PageTitle} />}
      {Canonical && <Helmet link={[{ rel: 'canonical', href: Canonical }]} />}
      {MetaDescription && <Helmet meta={[{ name: 'description', content: MetaDescription }]} />}
      {MetaRobots && <Helmet meta={[{ name: 'robots', content: MetaRobots }]} />}
      {LD_Schema && <Helmet meta={[{ name: 'application/ld+json', content: formatLD_Schema(LD_Schema) }]} />}
      {breadcrumbs && (
        <Helmet>
          <script type="application/ld+json">{formatStructuredBreadcrumbsLinks(breadcrumbs)}</script>
        </Helmet>
      )}
    </>
  )
}

StrapiSeo.propTypes = {
  data: PropTypes.object,
  breadcrumbs: PropTypes.object,
}

export default StrapiSeo

export const StrapiPageSeoFragment = graphql`
  fragment StrapiPageSeoFragment on BaseSeo {
    SEO {
      id
      PageTitle
      PageHeading
      Canonical
      MetaRobots
      MetaDescription
      Accordion {
        Accordion {
          ...StrapiPageAccordionFragment
        }
      }
      LD_Schema {
        _context
        _type
        name
        url
        telephone
        logo
        image
        priceRange
        description
        hasMap
        openingHours
        areaServed
        contactPoint {
          _type
          contactType
          telephone
        }
        paymentAccepted
        potentialAction {
          _type
          query_input
          target
        }
        geo {
          _type
          latitude
          longitude
        }
        address {
          _type
          addressCountry
          addressLocality
          addressRegion
          postalCode
          streetAddress
        }
      }
    }
  }
`
